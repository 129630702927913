import { UserOutlined } from '@ant-design/icons';
import { Logo } from '@components';
import { Avatar, Dropdown, MenuProps, Select } from 'antd';
import { useEffect, useState } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import api from '@services/api';
import { PlatformEnum } from '@types';

export type HeaderProps = {
  username: string;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const [platform, setPlatform] = useState<PlatformEnum>(PlatformEnum.SHOPIFY);

  useEffect(() => {
    const nav = document.getElementById('navigator');
    const navfilter = document.getElementById('navigator-filter');

    const scrollHandle = () => {
      if (!nav || !navfilter) return;
      const top = (window.pageYOffset || nav.scrollTop) - (nav.clientTop || 0);
      const barh = 0;

      if (top >= barh && nav.getAttribute('style') !== 'position: fixed') {
        nav.setAttribute(
          'style',
          `position: fixed;
          -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        `,
        );
        navfilter.setAttribute('style', 'display: block; height: 88px');
      } else if (top < barh && nav.getAttribute('style') !== 'position: relative') {
        nav.setAttribute('style', 'position: relative');
        navfilter.setAttribute('style', 'display: none; height: 88px');
      }
    };

    window.addEventListener('scroll', scrollHandle);

    return () => window.removeEventListener('scroll', scrollHandle);
  });

  const onHandleLogout = () => {
    api.removeToken();
    api.removeAuthenticated();
    window.location.href = '/login';
  };

  const items: MenuProps['items'] = [
    // {
    //   key: '1',
    //   label: <Link to="login">Profile</Link>,
    // },
    {
      key: '2',
      label: <div onClick={onHandleLogout}>Logout</div>,
    },
  ];

  const onHandleChangePlatform = (value: PlatformEnum) => {
    setPlatform(value);
  };

  return (
    <div className="header-container">
      <Logo />
      <div className="right-header">
        <Select
          defaultValue={platform}
          style={{ width: 200, marginRight: 50 }}
          onChange={onHandleChangePlatform}
          options={[
            { value: PlatformEnum.SHOPIFY, label: 'Shopify' },
            // { value: PlatformEnum.WIX, label: 'WIX' },
          ]}
        />
        <Dropdown menu={{ items }} placement="bottom">
          <div className="avatar">
            <h3>
              {props?.username?.slice(0, 8)} <span>...</span>
            </h3>
            <Avatar icon={<UserOutlined rev={undefined} />} style={{ backgroundColor: '#ffc225' }} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
