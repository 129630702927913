import { AnyObject, Searcher, Sorter } from '@services/@types';
import { GetPaginationFromAntdTable, GetSorterParamsFromAntdTable } from '@types';
import { get } from 'lodash';

export const copyToClipboard = (value: string) => {
  const tempInput = document.createElement('input');
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  navigator.clipboard.writeText(tempInput.value);
  document.body.removeChild(tempInput);
};

export const formatCurrencyBase = (amount: number) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const floorWithDecimal = (n: number, decimal: number = 0) =>
  Math.floor(n * Math.pow(10, decimal)) / Math.pow(10, decimal);

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const addCommasNumber = (num: number | string | undefined) =>
  num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
export const removeNonNumeric = (num: number | string) => num.toString().replace(/[^0-9.]/g, '');
export const removeNoNumeric = (num: number | string) => num.toString().replace(/[^0-9]/g, '');
export const removeCommasNumber = (num: number | string) => num.toString().replace(/[,]/g, '');

export const downloadFile = (name: string, url: string, filetype: string) => {
  return new Promise<void>((resolve, reject) => {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}.${filetype}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};

export const getPaginationFromAntdTable = <T>({ extra, pagination }: GetPaginationFromAntdTable<T>) => {
  const isFilterAction = extra.action === 'filter';
  if (isFilterAction) {
    return 0;
  }
  if (!pagination.current) {
    return 0;
  }
  return pagination.current - 1;
};

export const getSorterParamsFromAntdTable = <T extends AnyObject>({
  sorter,
}: GetSorterParamsFromAntdTable<T>): Sorter<T> => {
  const sorter_: Sorter<T> = Array.isArray(sorter)
    ? sorter.reduce<Sorter<T>>((res, sortComlumn) => {
        if (sortComlumn.columnKey && sortComlumn.order) {
          return {
            ...res,
            [sortComlumn.columnKey as string]: sortComlumn.order === 'ascend' ? 'asc' : 'desc',
          } as Sorter<T>;
        }
        return res;
      }, {})
    : sorter.columnKey && sorter.order
    ? ({ [sorter.columnKey as string]: sorter.order === 'ascend' ? 'asc' : 'desc' } as Sorter<T>)
    : {};
  return sorter_;
};

export const getSortParams = <T extends AnyObject>(sorter: any, withDefault = true) => {
  return Object.keys(sorter).reduce<Sorter<T>>(
    (res, sorterKey) => {
      const key = `${sorterKey}[sort]`;
      return {
        ...res,
        [key]: sorter[sorterKey],
      };
    },
    withDefault
      ? ({
          'createdAt[sort]': 'desc',
        } as Sorter<T>)
      : {},
  );
};

export const getSearchParams = <T extends AnyObject>(searcher: any) => {
  return Object.keys(searcher).reduce<Record<string, string>>((res, searchKey) => {
    const searchItem = get(searcher, searchKey);
    if (searchItem && !Array.isArray(searchItem)) {
      const { operator, value } = searchItem;
      const key = `${searchKey}[${operator}]`;
      if (value) {
        return {
          ...res,
          [key]: typeof value === 'string' ? value.trim() : value,
        };
      }
      return res;
    }
    if (searchItem && Array.isArray(searchItem)) {
      const groupParams = searchItem.reduce<Record<string, string>>((result, item) => {
        const { operator, value } = item;
        const key = `${searchKey}[${operator}]`;
        return {
          ...result,
          [key]: typeof value === 'string' ? value.trim() : value,
        };
      }, {});

      return {
        ...res,
        ...groupParams,
      };
    }
    return res;
  }, {});
};

// turns query string back into an object
export const queryStringToObject = (queryString = '', options: any) => {
  let queryObject = {} as any;
  queryString &&
    decodeURIComponent(queryString.replace('?', ''))
      .split('&')
      .map((itemString) => {
        let [itemKey, itemValue] = itemString.split('=');
        if (options.hasOwnProperty(itemKey)) {
          if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
            queryObject[itemKey] = [];
          }
          Array.isArray(options[itemKey])
            ? queryObject[itemKey].push(itemValue)
            : (queryObject[itemKey] = typeof options[itemKey] === 'number' ? parseInt(itemValue) : itemValue);
        }
      });
  return queryObject;
};
