import { useState } from 'react';

export type LayoutHookProps = {
  isCollapseSidebar: boolean;
  onHandleCollapseSidebar: () => void;
};
export const useLayoutHook = (): LayoutHookProps => {
  const [isCollapseSidebar, setCollapseSidebar] = useState<boolean>(true);

  const onHandleCollapseSidebar = () => {
    return setCollapseSidebar(!isCollapseSidebar);
  };

  return { isCollapseSidebar, onHandleCollapseSidebar };
};
