import { ROUTERS_MAIN } from '@constants';
import { SidebarItemTypes } from './types';
import { RoleEnum } from '@types';

export const SIDEBAR_ITEMS: SidebarItemTypes[] = [
  // {
  //   key: 'user-management',
  //   path: ROUTERS_MAIN.USER,
  //   label: 'Management Users',
  //   icon: 'sidebar_user',
  //   roles: [RoleEnum.PARTNER_ADMIN, RoleEnum.PARTNER_ADMIN_MEMBER],
  // },
  {
    key: 'dashboard',
    path: ROUTERS_MAIN.DASHBOARD,
    label: 'Dashboard',
    icon: 'sidebar_dashboard',
    roles: [RoleEnum.ROOT],
  },
  {
    key: 'partners',
    path: ROUTERS_MAIN.PARTNER,
    label: 'Management Partners',
    icon: 'sidebar_partner',
    roles: [RoleEnum.ROOT],
  },
  {
    key: 'sub-management',
    path: ROUTERS_MAIN.SUBCRIPTION,
    label: 'Management Subscriptions',
    icon: 'sidebar_sub',
    roles: [RoleEnum.ROOT],
  },
  {
    key: 'sub-management',
    path: ROUTERS_MAIN.PARTNER_SUBCRIPTION,
    label: 'Management Subscriptions',
    icon: 'sidebar_sub',
    roles: [RoleEnum.PARTNER_ADMIN],
  },
  {
    key: 'apps',
    path: ROUTERS_MAIN.APP,
    label: 'Management Apps',
    icon: 'sidebar_app',
    roles: [RoleEnum.PARTNER_ADMIN, RoleEnum.PARTNER_ADMIN_MEMBER, RoleEnum.ROOT],
  },
  {
    key: 'keyword',
    path: ROUTERS_MAIN.KEYWORD,
    label: 'Management Keywords',
    icon: 'sidebar_keyword',
    roles: [RoleEnum.PARTNER_ADMIN, RoleEnum.PARTNER_ADMIN_MEMBER, RoleEnum.ROOT],
  },
  {
    key: 'configEnv',
    path: ROUTERS_MAIN.CONFIG_ENV,
    label: 'Management Config',
    icon: 'sidebar_config',
    roles: [RoleEnum.ROOT],
  },
  {
    key: 'subTx',
    path: ROUTERS_MAIN.SUB_TX,
    label: 'Management Transaction',
    icon: 'sidebar_sub1',
    roles: [RoleEnum.ROOT],
  },
  {
    key: 'subPartnerTx',
    path: ROUTERS_MAIN.SUB_PARTNER_TX,
    label: 'Management Transaction',
    icon: 'sidebar_sub1',
    roles: [RoleEnum.PARTNER_ADMIN, RoleEnum.PARTNER_ADMIN_MEMBER],
  },
  {
    key: 'category',
    path: ROUTERS_MAIN.CATEGORY,
    label: 'Management Categories',
    icon: 'sidebar_category',
    roles: [RoleEnum.ROOT],
  },
  {
    key: 'collection',
    path: ROUTERS_MAIN.COLLECTION,
    label: 'Management Collections',
    icon: 'sidebar_collection',
    roles: [RoleEnum.ROOT],
  },
  {
    key: 'blogs',
    path: ROUTERS_MAIN.BLOG,
    label: 'Management Blogs',
    icon: 'sidebar_blog',
    roles: [RoleEnum.ROOT],
  },
  // {
  //   key: 'PARTNER_ADD_APPS',
  //   path: ROUTERS_MAIN.PARTNER_ADD_APPS,
  //   label: 'Add New App',
  //   icon: 'sidebar_app',
  //   roles: [RoleEnum.PARTNER_ADMIN, RoleEnum.PARTNER_ADMIN_MEMBER],
  // },
];
