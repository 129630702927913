import { FeatureEnum } from './admin-subscription';
import { RecordStatus } from './base';

export enum RoleEnum {
  ROOT = 'ROOT',
  PARTNER_ADMIN = 'PARTNER_ADMIN',
  PARTNER_ADMIN_MEMBER = 'PARTNER_ADMIN_MEMBER',
}

export interface PartnerConnectorDocument {
  _id: string;
  fullname: string;
  google_token: {
    client_id: string;
    client_secret: string;
    refresh_token: string;
    scopes: Array<string>;
    token: string;
    token_uri: string;
  };
  currentSubscription: {
    subscriptionType: SubscriptionEnum;
  };
  password: string;
  hash_user?: string;
  active_code?: string;
  totalApps: number;
  totalKeywords: number;
  username: string;
  inactive: boolean;
  created_at: string;
  updated_at: string;
  createdAt: string;
  last_login_at:string
}

export interface UserLoginParams {
  username: string;
  password: string;
}

export interface AdminPartnerDocumentType {
  _id: string;
  partnerCode: string;
  partnerAccount: string;
  username: string;
  role: RoleEnum;
  status: RecordStatus;
}

export enum SubscriptionEnum {
  TRIAL = 'TRIAL',
  STANDARD = 'STANDARD',
  PRO = 'PRO',
  UNLIMITED = 'UNLIMITED',
  EXPIRED = 'EXPIRED',
}

export type UpdateProductPlanParam = {
  name: string;
  monthlyPrice: number;
  trialValue: number;
  appValue: number;
  keywordValue: number;
};

//- Dữ liệu gửi lên server
export type UpdateProductPlanRequestParam = {
  name: string;
  monthlyPrice: number;
  features: Array<{ type: FeatureEnum; value: string | number }>;
};

export type CreateAdminMemberParam = {
  username: string;
};
